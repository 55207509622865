import { useState, useEffect } from "react";
import { ArcgisExpand, ArcgisLayerList, ArcgisLegend, ArcgisMap, ArcgisZoom } from "@arcgis/map-components-react";
import { Grid } from "@mui/material";
// import defineCustomElements to register custom elements with the custom elements registry
import { defineCustomElements } from "@arcgis/map-components/dist/loader";
import { ArcgisMapCustomEvent } from "@arcgis/map-components/dist/types/components";
import Expand from "@arcgis/core/widgets/Expand";
import MapView from "@arcgis/core/views/MapView";
import "@arcgis/core/assets/esri/css/main.css";

import { MapToolbox } from "./MapToolbox";
import { createRoot } from "react-dom/client";
defineCustomElements(window, { resourcesUrl: "https://js.arcgis.com/map-components/4.30/assets" });

import { useARContext, useAuthorization } from "@/context";
import { ACCEPTED_MAP_FILE_EXTENSIONS, AR_MAP_UPLOAD_ERROR_BANNER } from "@/constants";
import { AddApprovalRequestMap } from "@/interfaces";
import { useAddApprovalRequestMap } from "@/hooks";
import { UploadComponent } from "@/components/upload";

const { REACT_APP_ARCGIS_WEBMAP_ITEM_ID } = import.meta.env;
type WidgetComponent = typeof ArcgisLayerList | typeof ArcgisLegend | typeof ArcgisZoom;

interface MapRenderProps {
  mapLoaded: boolean;
  setMapLoaded: React.Dispatch<React.SetStateAction<boolean>>;
}

interface MapWidgetProps {
  name: string;
  component: WidgetComponent;
  componentProps?: Record<string, unknown>;
  isExpandable: boolean;
}

const BOTTOM_RIGHT_WIDGETS: MapWidgetProps[] = [
  {
    name: "Zoom",
    component: ArcgisZoom,
    isExpandable: false,
    componentProps: { layout: "vertical" }
  },
  { name: "Layers", component: ArcgisLayerList, isExpandable: true },
  { name: "Legends", component: ArcgisLegend, isExpandable: true }
];

export function MapRender({ mapLoaded, setMapLoaded }: MapRenderProps) {
  const mapPanel = document.createElement("div");
  const [fullScreen, setFullScreen] = useState(false);
  const { approvalRequest, approvalRequestId, setUploadInProgress, appendAlertBanner, removeAlertBanner } =
    useARContext();
  const { isRequestor } = useAuthorization();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [mapView, setMapView] = useState<MapView>();
  const [rootPanel] = useState(createRoot(mapPanel));

  const setMapScale = (event: ArcgisMapCustomEvent<void>) => {
    const view = event.target.view;
    if (view) {
      // Set the minimum and maximum scale
      view.constraints = {
        minScale: 9000000,
        maxScale: 35
      };
    }
  };

  const addMapToolboxPanel = (event: ArcgisMapCustomEvent<void>) => {
    const view = event.target.view;
    if (view) {
      const expand = new Expand({
        view: view,
        content: mapPanel,
        expanded: true
      });

      view.ui.add(expand, "top-left");
      setMapView(view);
    }
  };

  const renderMapToolboxPanel = () => {
    if (mapView)
      rootPanel.render(
        <MapToolbox
          setFullScreen={setFullScreen}
          mapView={mapView}
          approvalRequestStatus={approvalRequest.approvalRequestStatus}
          isRequestor={isRequestor}
        />
      );
  };

  const { mutate: updateMapMutation } = useAddApprovalRequestMap(approvalRequestId);

  const uploadFile = async (fileToUpload: File) => {
    setUploadInProgress(true);

    const fileData = new FormData();
    fileData.append("file", fileToUpload);

    const addMapCommand: AddApprovalRequestMap = {
      approvalRequestId,
      fileData
    };

    updateMapMutation(addMapCommand, {
      onSettled: () => {
        setUploadInProgress(false);
      }
    });
  };

  useEffect(() => {
    if (errorMessage) {
      appendAlertBanner({
        ...AR_MAP_UPLOAD_ERROR_BANNER,
        message: errorMessage
      });
    } else if (errorMessage === "") {
      removeAlertBanner([AR_MAP_UPLOAD_ERROR_BANNER.id]);
    }
  }, [errorMessage, appendAlertBanner]);

  useEffect(() => {
    renderMapToolboxPanel();
  }, [mapView, isRequestor]);

  return (
    <Grid
      className={fullScreen ? "ar-map-fullscreen" : ""}
      data-testid="map-render-display"
      visibility={mapLoaded ? "visible" : "hidden"}
      sx={{ flexDirection: "column", flexGrow: 1, height: "100%" }}
      style={{ margin: "0 auto" }}
    >
      <UploadComponent
        handleUpload={uploadFile}
        acceptedFileExtensions={ACCEPTED_MAP_FILE_EXTENSIONS}
        allowMultipleFile={false}
        setErrorMessage={setErrorMessage}
        validateMapFile={true}
      />
      <ArcgisMap
        itemId={REACT_APP_ARCGIS_WEBMAP_ITEM_ID}
        onArcgisViewReadyChange={(event) => {
          setMapLoaded(true);
          setMapScale(event);
          addMapToolboxPanel(event);
        }}
      >
        {BOTTOM_RIGHT_WIDGETS.map((item) => {
          const Component = item.component;
          if (!item.isExpandable) return <Component position="bottom-right" {...(item.componentProps || {})} />;

          return (
            <ArcgisExpand
              position="bottom-right"
              expandTooltip={`Open ${item.name}`}
              collapseTooltip={`Close ${item.name}`}
              autoCollapse={true}
            >
              <Component {...(item.componentProps || {})} />
            </ArcgisExpand>
          );
        })}
      </ArcgisMap>
    </Grid>
  );
}
