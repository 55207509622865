import { apiClient } from "@/api/client";
import { EMPTY_PAGINATED_RESPONSE, HUBS_DATA_QUERY_KEY } from "@/constants";
import { AddHubRequest, UpdateHubRequest, PutHubRequest, HubList } from "@/interfaces";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

const basePath = "/hub";

export function useGetHubsData(pageNumber: number, pageSize: number, onSuccess?: (hubs: HubList) => void) {
  return useQuery({
    queryKey: [HUBS_DATA_QUERY_KEY, pageSize, pageNumber],
    queryFn: () =>
      apiClient
        .get<HubList>(`${basePath}`, {
          params: { pageNumber, pageSize }
        })
        .then((response) => response.data),
    onSuccess,
    initialData: EMPTY_PAGINATED_RESPONSE as HubList
  });
}

export function useCreateNewHub(onSuccessCallback?: () => void, onErrorCallback?: () => void) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (request: AddHubRequest) => apiClient.post<AddHubRequest>(`${basePath}`, request),
    onSuccess: () => {
      onSuccessCallback?.();
      queryClient.invalidateQueries([HUBS_DATA_QUERY_KEY]);
    },
    onError: () => {
      onErrorCallback?.();
    }
  });
}

export function useUpdateHub(onSuccessCallback?: () => void) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (request: UpdateHubRequest) => apiClient.put<UpdateHubRequest>(`${basePath}`, request),
    onSuccess: () => {
      onSuccessCallback?.();
      queryClient.invalidateQueries([HUBS_DATA_QUERY_KEY]);
    }
  });
}

export function useDeleteHub(onSuccessCallback?: () => void) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (request: PutHubRequest) => apiClient.put<PutHubRequest>(`${basePath}/delete`, request),
    onSuccess: () => {
      onSuccessCallback?.();
      queryClient.invalidateQueries([HUBS_DATA_QUERY_KEY]);
    }
  });
}
