import { useEffect } from "react";
import { Box, Typography } from "@mui/material";

import { AddApprovalRequestMap } from "@/interfaces";
import { useARContext } from "@/context";
import { useAddApprovalRequestMap } from "@/hooks";
import { AttachmentUploadBox } from "@/features/my-requests/components";
import { ACCEPTED_MAP_FILE_EXTENSIONS } from "@/constants";

interface MapUploadProps {
  setIsMapExist: (isMapExist: boolean) => void;
}

export const MapUpload = ({ setIsMapExist }: MapUploadProps) => {
  const { approvalRequestId, setUploadInProgress } = useARContext();

  useEffect(() => {
    setUploadInProgress(false);
  }, [setUploadInProgress]);

  const { mutate: updateMapMutation } = useAddApprovalRequestMap(approvalRequestId, () => {
    setIsMapExist(true);
    setUploadInProgress(false);
  });

  const uploadFile = async (fileToUpload: File) => {
    setUploadInProgress(true);

    const fileData = new FormData();
    fileData.append("file", fileToUpload);

    const addMapCommand: AddApprovalRequestMap = {
      approvalRequestId,
      fileData
    };

    updateMapMutation(addMapCommand);
  };

  const customFooter = (
    <Typography data-testid="upload-footer-display" variant="body2" mt="0.5rem" color="#0000008A">
      Shape Zip File (max. 5M)
    </Typography>
  );

  return (
    <Box data-testid="map-upload-display" flexDirection="column" flexGrow="1" height="100%" m="0 auto">
      <AttachmentUploadBox
        handleUpload={uploadFile}
        fillHeight={true}
        customFooter={customFooter}
        allowMultipleFile={false}
        validateMapFile={true}
        acceptedFileExtensions={ACCEPTED_MAP_FILE_EXTENSIONS}
      />
    </Box>
  );
};
